import { useTranslation } from '@getpopsure/i18n-react';
import { ComparisonTable as Table } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { isMobileApp } from 'shared/util/isMobileApp';

import {
  DentalQuotePlan,
  QuoteTableData,
  QuoteTableModal,
} from '../../../models';
import { TableModal } from '../TableModal';
import styles from './style.module.scss';
import { getTableData, getTableHeaders } from './tableData/dental';

export const ComparisonTable = ({
  showPrice,
  planMapping,
}: {
  showPrice: boolean;
  planMapping: DentalQuotePlan;
}) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<null | QuoteTableModal>();

  const handleOpenModal = useCallback((newModal: QuoteTableModal) => {
    setModal(newModal);
  }, []);

  const tableData: Array<QuoteTableData> = getTableData(t);

  const tableHeaders = getTableHeaders(
    t,
    handleOpenModal,
    showPrice,
    planMapping
  );

  return (
    <div className={styles.table}>
      <header
        className={classNames('fd-column ai-center pb24', styles.tableHeader)}
      >
        <h2 className="p-h2 p--serif">
          {t(
            'dental.qnr.preQuote.quote.coverageTable.title',
            'Coverage overview'
          )}
        </h2>
        <div className={classNames('ws2', styles.headerUnderline)} />
      </header>
      <Table
        headers={tableHeaders}
        data={tableData}
        collapsibleSections
        growContent
        cellWidth={160}
        firstColumnWidth={288}
        stickyHeaderTopOffset={isMobileApp ? 88 : 0}
      />
      {modal &&
        createPortal(
          <TableModal modal={modal} setModal={setModal} />,
          document.body
        )}
    </div>
  );
};
