import {
  Header,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { IbanInput, InformationBox } from '@popsure/dirty-swan';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';
import { isValidIban } from 'shared/util/isValidIban';

export const PayoutDetails = <Questionnaire extends QuestionnaireQuestions>({
  onSubmitValue,
  setValidForSubmission,
  value,
}: QuestionnaireFormProps<Questionnaire, string>) => {
  const [validationError, setValidationError] = useState('');

  const [iban, setIban] = useState<string>(value || '');

  const { t } = useSafeTranslation();

  const handleOnIbanChange = (ibanValue: string) => {
    const isValid = !!ibanValue && isValidIban(ibanValue);

    if (isValid) {
      setValidationError('');
    }

    setValidForSubmission(isValid);
    setIban(ibanValue);
  };

  const handleSubmit = () => {
    if (!iban || !isValidIban(iban)) {
      setValidationError('Enter a valid IBAN');
      return;
    }

    onSubmitValue(iban);
  };

  return (
    <Header onSubmit={handleSubmit}>
      <div className="mt24">
        <h4 className="p-h1 mb8">
          {t('claims.expatLongTerm.payoutDetails.iban.title', 'Bank details')}
        </h4>
        <p className="p-p mb8">
          {t(
            'claims.expatLongTerm.payoutDetails.iban.description',
            "The IBAN of your account where you'll receive the payout if approved"
          )}
        </p>
        <IbanInput
          placeholder={t(
            'claims.expatLongTerm.payoutDetails.iban.placeholder',
            'IBAN'
          )}
          value={iban}
          onChange={handleOnIbanChange}
        />
      </div>
      <AnimateHeight duration={300} height={validationError ? 'auto' : 0}>
        <InformationBox variant="warning" className="mt16">
          {validationError}
        </InformationBox>
      </AnimateHeight>
    </Header>
  );
};
