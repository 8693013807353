import { changeLanguage } from '@getpopsure/i18n-react';
import { useFlag } from '@unleash/proxy-client-react';
import { DelinquencyBlockerPage } from 'components/DelinquencyBlocker';
import PageNotFound from 'components/pageNotFound';
import routes from 'constants/routes';
import AuthenticatedRoute from 'containers/authenticatedRoute';
import {
  Account,
  AccountSettings,
  BillingAndPayment,
  MailingPreferences,
  PrivacyAndTerms,
} from 'features/accountV2';
import { SignupPage as Bike } from 'features/bike';
import { BikeClaims } from 'features/bikeClaims';
import { BillingHistory } from 'features/billingHistory';
import BrokerMandate from 'features/brokerMandate/containers';
import ConfirmPaymentRedirectGateway from 'features/checkout/components/ConfirmPaymentRedirectGateway/ConfirmPaymentRedirectGateway.container';
import CheckoutStripeRedirectGateway from 'features/checkout/components/StripeRedirectGateway/StripeRedirectGateway.container';
import GenericClaimConfirmation from 'features/claims/components/confirmation';
import Claims from 'features/claims/containers';
import LegalConfirmation from 'features/claims/containers/legalClaim/confirmation';
import LegalClaimConfirmInfo from 'features/claims/containers/legalClaim/confirmInfo';
import LegalDescribeClaim from 'features/claims/containers/legalClaim/describeClaim';
import LegalProcessing from 'features/claims/containers/legalClaim/processing';
import ClaimStatus from 'features/claimsProcess/claimStatus';
import ClaimSteps from 'features/claimsProcess/claimSteps';
import ClaimTypes from 'features/claimsProcess/claimTypes';
import ClaimsConfirmation from 'features/claimsProcess/confirmation';
import MyClaims from 'features/claimsProcess/myClaims';
import ClaimsPaymentInfo from 'features/claimsProcess/paymentInfo';
import ClaimsProcessing from 'features/claimsProcess/processing';
import ClaimsUploadDocuments from 'features/claimsProcess/uploadDocuments';
import ClaimsDashboard from 'features/claimsV2';
import { ClaimDetail } from 'features/claimsV2/components/claimDetail';
import { CreateClaim } from 'features/claimsV2/components/createClaim';
import { CommpanyHealthClaims } from 'features/companyHealthClaims';
import { SignupPage as DentalSignup } from 'features/dental';
import { DentalClaims } from 'features/dentalClaims';
import { Signup as DisabilitySignup } from 'features/disability';
import { DisabilitySuccessScreen } from 'features/disability/DisabilitySuccessScreen';
import { SignupPage as DogLiabilitySignup } from 'features/dogLiability';
import { DogLiabilityClaims } from 'features/dogLiabilityClaims/DogLiabilityClaims';
import ExpatSignupPage from 'features/expat';
import { ExpatClaims } from 'features/expatClaims/ExpatClaims';
import { ExpatEuClaims } from 'features/expatEuClaims/ExpatEuClaims';
import { ExpatLongTermClaims } from 'features/expatLongTermClaims/ExpatLongTermClaims';
import { ExpatSpainRouter } from 'features/expatSpain/ExpatSpainRouter';
import { ExpatSpainClaims } from 'features/expatSpainClaims/ExpatSpainClaims';
import HealthCardPhoto from 'features/healthCard/mobilePhotoUpload';
import { SignupPage as HouseholdSignupPage } from 'features/household';
import { HouseholdClaims } from 'features/householdClaims';
import { SignupPage as LegalSignup } from 'features/legal';
import {
  BookConsultation,
  ClaimsAndConsultations,
  SubmitClaim,
} from 'features/legalClaims';
import { Claims as ClaimsAndConsultationsV2 } from 'features/legalClaimsV2';
import Liability from 'features/liability';
import { LiabilityClaims } from 'features/liabilityClaims';
import { AddBeneficiaries } from 'features/lifeBeneficiaries';
import { EditBeneficiaries } from 'features/lifeBeneficiaries/components/EditBeneficiariesWrapper';
import Base from 'features/main';
import InsuranceOptions from 'features/main/insuranceOptions';
import {
  AddPaymentMethod,
  BillingScreen,
} from 'features/paymentMethods/containers';
import { StripeRedirectGateway } from 'features/paymentMethods/containers/StripeRedirectGateway/StripeRedirectGateway.container';
import PaymentScreen from 'features/paymentScreen/paymentScreen.router';
import { SignupPage as PetHealthSignup } from 'features/petHealth';
import { PoliciesDashboard } from 'features/policiesDashboard';
import { PoliciesList } from 'features/policiesDashboard/modules/PoliciesList';
import { Signup as PrivatePreSignup } from 'features/privateHealthPreSignup';
import { FetchQuote as PrivateHealthFetchQuote } from 'features/privateHealthPreSignup/components/FetchQuote';
import { RecommendationTool } from 'features/recommendationTool';
import { InsuranceToolRoutes } from 'features/recommendationTool/newRoutes';
import { ReferralPayoutDetails } from 'features/referralEngine';
import { SeedQuestionnaire } from 'features/seedQuestionnaire/SeedQuestionnaire';
import SignIn from 'features/signIn/containers';
import { SignupRouting } from 'features/signUp';
import { Unsubscribed } from 'features/subscription/components/Unsubscribed';
import TaxStatements from 'features/taxStatements';
import {
  TravelHealthSignupTally,
  TravelHealthSuccessScreen,
} from 'features/travelHealth';
import { SignupTally as TravelHealthFranceSignupTally } from 'features/travelHealthFrance';
import { VideoDoctorAppointment } from 'features/videoDoctorAppointment';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { memo, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { validateLocale } from 'shared/i18n';
import { isStagingOrDev } from 'shared/util/isStagingOrDev';

import { SignupPage as DisabilityV2 } from './features/disabilityV2';
import { SignupPage as DogLiabilitySpain } from './features/dogLiabilitySpain';
import { ExpatEuRouter as ExpatEu } from './features/expatEu';
import { ExpatLongTermRouter as ExpatLongTerm } from './features/expatLongTerm';
import { SignupPage as IncomeProtectionSignup } from './features/incomeProtection';
import { SignupPage as LifeInsurance } from './features/life';
import { SignupPage as Pension } from './features/pension';
import { PolicySingle } from './features/policySingle';
import { PrivateHealthClaimsRouter } from './features/privateHealthClaims/PrivateHealthClaimsRouter';
import { SignupPage as TravelHealthV1 } from './features/travelHealthV1';
import { Builder } from './SignupQuestionnaire';

const Routes = () => {
  const RedirectToInitialScreen = () => {
    const { search } = useLocation();
    return <Redirect to={`${routes.me.policies.path}${search}`} />;
  };

  const isTravelHealthV1 = useFlag('app_travel_health_v1');
  const isExpatSpain = useFlag('app_expat_spain');
  const isIncomeProtection = useFlag('app_income_protection');
  const isTravelFrance = useFlag('app_travel_france');
  const isLegalClaimsV2 = useFlag('app_legal_claims_v2');
  const isExpatEu = useFlag('app_expat_eu');
  const isExpatLongTerm = useFlag('app_expat_lt');
  const isDisabilityV2 = useFlag('app_disability_v1');
  const isPension = useFlag('app_pension');
  const isDogLiabilitySpain = useFlag('app_dog_liability_spain');

  const language = useQueryParamValue('lang');

  useEffect(() => {
    if (language) {
      const locale = validateLocale(language);
      changeLanguage(locale);
    }
  }, [language]);

  return (
    <Switch>
      <Route exact={true} path={routes.base.path} component={Base} />
      <Route
        exact={true}
        path={routes.base.insuranceOptions.path}
        component={InsuranceOptions}
      />
      <Route
        exact={true}
        path={routes.base.insuranceTools.path}
        render={() => <Redirect to={routes.insuranceTool.path} />}
      />
      <Route
        path={routes.checkup.path}
        render={() => <Redirect to={routes.insuranceTool.path} />}
      />
      <AuthenticatedRoute
        exact={true}
        path={routes.me.path}
        component={RedirectToInitialScreen}
      />
      <AuthenticatedRoute
        exact={true}
        path={routes.account.path}
        component={Account}
      />
      <AuthenticatedRoute
        exact={true}
        path={routes.me.accountSettings.path}
        component={AccountSettings}
      />
      <AuthenticatedRoute
        exact={true}
        path={routes.me.communicationPreferences.path}
        component={MailingPreferences}
      />
      <AuthenticatedRoute
        exact={true}
        path={routes.me.brokerMandate.sign.path}
        component={BrokerMandate}
      />
      <Route
        exact={true}
        path={routes.account.signIn.path}
        component={SignIn}
      />
      <Route path={routes.account.signUp.path} component={SignupRouting} />
      <Route
        path={routes.policies.dogLiability.path}
        component={DogLiabilitySignup}
      />
      <Route
        path={routes.policies.petHealth.path}
        component={PetHealthSignup}
      />
      <Route
        path={routes.healthInsuranceTool.path}
        component={RecommendationTool}
      />
      <Route
        exact={true}
        path={routes.me.policies.list.path}
        component={PoliciesList}
      />
      <AuthenticatedRoute
        exact={true}
        path={routes.me.policies.path}
        component={PoliciesDashboard}
      />
      <AuthenticatedRoute
        exact={true}
        path={routes.me.policies.detail.path}
        component={PolicySingle}
      />
      <AuthenticatedRoute
        exact
        path={routes.me.policies.detail.studentChecklist.path}
        component={PolicySingle}
      />
      <AuthenticatedRoute
        exact={true}
        path={routes.claims.path}
        component={ClaimsDashboard}
      />
      <AuthenticatedRoute
        exact={true}
        path={routes.me.claims.path}
        component={Claims}
      />
      <AuthenticatedRoute
        exact={true}
        path={routes.me.claims.confirmationGeneric.path}
        component={GenericClaimConfirmation}
      />
      <AuthenticatedRoute
        exact={true}
        path={routes.me.claims.legalClaim.confirmInfo.path}
        component={LegalClaimConfirmInfo}
      />
      <AuthenticatedRoute
        exact={true}
        path={routes.me.claims.legalClaim.describeClaim.path}
        component={LegalDescribeClaim}
      />
      <AuthenticatedRoute
        exact={true}
        path={routes.me.claims.legalClaim.processing.path}
        component={LegalProcessing}
      />
      <AuthenticatedRoute
        exact={true}
        path={routes.me.claims.legalClaim.confirmation.path}
        component={LegalConfirmation}
      />
      <AuthenticatedRoute
        exact={true}
        path={routes.me.claims.myClaims.path}
        component={MyClaims}
      />
      <AuthenticatedRoute
        exact={true}
        path={routes.me.claims.claimStatus.path}
        component={ClaimStatus}
      />
      <AuthenticatedRoute
        exact={true}
        path={routes.me.claims.claimSteps.path}
        component={ClaimSteps}
      />
      <AuthenticatedRoute
        exact={true}
        path={routes.me.claims.claimTypes.path}
        component={ClaimTypes}
      />
      <AuthenticatedRoute
        exact={true}
        path={routes.me.claims.uploadDocuments.path}
        component={ClaimsUploadDocuments}
      />
      <AuthenticatedRoute
        exact={true}
        path={routes.me.claims.paymentInfo.path}
        component={ClaimsPaymentInfo}
      />
      <AuthenticatedRoute
        exact={true}
        path={routes.me.claims.processing.path}
        component={ClaimsProcessing}
      />
      <AuthenticatedRoute
        exact={true}
        path={routes.me.claims.confirmation.path}
        component={ClaimsConfirmation}
      />
      <AuthenticatedRoute
        exact={false}
        path={routes.claims.liability.path}
        component={LiabilityClaims}
      />
      <AuthenticatedRoute
        exact={false}
        path={routes.claims.dental.path}
        component={DentalClaims}
      />
      <AuthenticatedRoute
        exact={false}
        path={routes.claims.companyHealth.path}
        component={CommpanyHealthClaims}
      />
      <AuthenticatedRoute
        exact={false}
        path={routes.claims.dogLiability.path}
        component={DogLiabilityClaims}
      />
      <AuthenticatedRoute
        exact={false}
        path={routes.policies.expat.videoDoctorAppointment.path}
        component={VideoDoctorAppointment}
      />
      <AuthenticatedRoute
        exact={false}
        path={routes.claims.household.path}
        component={HouseholdClaims}
      />
      <AuthenticatedRoute
        exact={false}
        path={routes.claims.expat.path}
        component={ExpatClaims}
      />
      <AuthenticatedRoute
        exact={false}
        path={routes.claims.expatEu.path}
        component={ExpatEuClaims}
      />
      <AuthenticatedRoute
        exact={false}
        path={routes.claims.expatLongTerm.path}
        component={ExpatLongTermClaims}
      />
      <AuthenticatedRoute
        exact={false}
        path={routes.claims.expatSpain.path}
        component={ExpatSpainClaims}
      />
      <Route
        // Redirects all incoming routes to new flow
        exact={false}
        path="/policies/incoming"
        component={() => <Redirect to={routes.policies.expat.path} />}
      />
      <Route
        path={routes.policies.household.path}
        component={HouseholdSignupPage}
      />
      {!isDisabilityV2 && (
        <AuthenticatedRoute
          exact={true}
          path={routes.policies.disability.success.path}
          component={DisabilitySuccessScreen}
        />
      )}
      {!isLegalClaimsV2 && (
        <AuthenticatedRoute
          exact={false}
          path={routes.claims.legal.submitClaim.path}
          component={SubmitClaim}
        />
      )}
      {!isLegalClaimsV2 && (
        <AuthenticatedRoute
          exact={false}
          path={routes.claims.legal.bookConsultation.path}
          component={BookConsultation}
        />
      )}
      {isLegalClaimsV2 ? (
        <AuthenticatedRoute
          exact={false}
          path={routes.claims.legal.path}
          component={ClaimsAndConsultationsV2}
        />
      ) : (
        <AuthenticatedRoute
          exact={false}
          path={routes.claims.legal.claimsAndConsultations.path}
          component={ClaimsAndConsultations}
        />
      )}
      <AuthenticatedRoute
        exact={true}
        path={routes.policies.travelHealth.success.path}
        component={TravelHealthSuccessScreen}
      />
      {!isDisabilityV2 && (
        <Route
          path={routes.policies.disability.path}
          component={DisabilitySignup}
        />
      )}
      {isDisabilityV2 && (
        <Route
          path={routes.policies.disabilityV2.path}
          component={DisabilityV2}
        />
      )}
      <AuthenticatedRoute
        exact={true}
        path={routes.me.paymentMethods.path}
        component={BillingScreen}
      />
      <AuthenticatedRoute
        exact={true}
        component={BillingAndPayment}
        path={routes.account.billingAndPayment.path}
      />
      <AuthenticatedRoute
        exact={true}
        component={BillingHistory}
        path={routes.account.billingHistory.path}
      />
      <AuthenticatedRoute
        exact={true}
        component={PrivacyAndTerms}
        path={routes.account.privacyAndTerms.path}
      />
      <AuthenticatedRoute
        exact={true}
        component={TaxStatements}
        path={routes.account.taxStatements.path}
      />
      <AuthenticatedRoute
        exact={true}
        component={AddPaymentMethod}
        path={routes.me.paymentMethods.add.path}
      />
      <AuthenticatedRoute
        exact={true}
        component={StripeRedirectGateway}
        path={routes.me.paymentMethods.confirmSetup.path}
      />
      <AuthenticatedRoute
        exact={true}
        component={ReferralPayoutDetails}
        path={routes.me.referralPayoutDetails.path}
      />
      <Route path={routes.policies.legal.path} component={LegalSignup} />
      <Route path={routes.policies.dental.path} component={DentalSignup} />
      {isTravelFrance && (
        <Route
          exact
          path={routes.policies.travelHealthFrance.path}
          component={TravelHealthFranceSignupTally}
        />
      )}
      <Route
        path={routes.policies.travelHealth.path}
        component={isTravelHealthV1 ? TravelHealthV1 : TravelHealthSignupTally}
      />
      <Route
        path={routes.policies.privateHealthV2.fetchQuote.path}
        component={PrivateHealthFetchQuote}
      />
      <Route
        path={routes.policies.privateHealthV2.path}
        component={PrivatePreSignup}
      />
      <AuthenticatedRoute
        exact={false}
        component={PrivateHealthClaimsRouter}
        path={routes.claims.privateHealth.path}
      />
      {isExpatSpain && (
        <Route
          path={routes.policies.expatSpain.path}
          component={ExpatSpainRouter}
        />
      )}

      {isExpatEu && (
        <Route path={routes.policies.expatEu.path} component={ExpatEu} />
      )}
      {isExpatEu && (
        <Route
          path={routes.policies.expatEu.noRegion.path}
          component={ExpatEu}
        />
      )}

      {isExpatLongTerm && (
        <Route
          path={routes.policies.expatLongTerm.path}
          component={ExpatLongTerm}
        />
      )}
      {isExpatLongTerm && (
        <Route
          path={routes.policies.expatLongTerm.noRegion.path}
          component={ExpatLongTerm}
        />
      )}

      <Route path={routes.policies.expat.path} component={ExpatSignupPage} />
      <Route path={routes.policies.bike.path} component={Bike} />
      {isIncomeProtection && (
        <Route
          path={routes.policies.incomeProtection.path}
          component={IncomeProtectionSignup}
        />
      )}
      {isDogLiabilitySpain && (
        <Route
          path={routes.policies.dogLiabilitySpain.path}
          component={DogLiabilitySpain}
        />
      )}
      <AuthenticatedRoute
        exact={false}
        component={BikeClaims}
        path={routes.claims.bike.path}
      />
      <AuthenticatedRoute
        exact={false}
        component={CreateClaim}
        path={routes.claims.create.path}
      />
      <AuthenticatedRoute
        exact={false}
        component={ClaimDetail}
        path={routes.claims.detail.path}
      />
      <Route
        path={routes.paymentScreenRegionalised.path}
        component={PaymentScreen}
      />
      <AuthenticatedRoute
        exact={false}
        component={CheckoutStripeRedirectGateway}
        path={routes.policies.checkoutSetupConfirmation.path}
      />
      <AuthenticatedRoute
        exact={false}
        component={ConfirmPaymentRedirectGateway}
        path={routes.policies.checkoutPaymentConfirmation.path}
      />
      <Route path={routes.paymentScreen.path} component={PaymentScreen} />
      <Route path={routes.policies.life.path} component={LifeInsurance} />
      <Route path={routes.healthCardPhoto.path} component={HealthCardPhoto} />
      <Route path={routes.policies.liability.path} component={Liability} />
      <Route path={routes.insuranceTool.path} component={InsuranceToolRoutes} />
      <Route
        path={routes.me.policies.life.beneficiaries.path}
        component={AddBeneficiaries}
      />
      <Route
        path={routes.me.policies.life.beneficiaries.edit.path}
        component={EditBeneficiaries}
      />
      {/* Subscription */}
      <Route
        path={routes.subscriptions.unsubscribed.path}
        component={Unsubscribed}
        exact
      />
      <Route
        path={routes.policies.delinquency.path}
        component={DelinquencyBlockerPage}
      />
      <Route path={routes.policies.seed.path} component={SeedQuestionnaire} />
      {/* Only to be used on staging or dev environment */}
      {isStagingOrDev && (
        <Route path={routes.policies.builder.path} component={Builder} />
      )}

      {isPension && (
        <Route path={routes.policies.pension.path} component={Pension} />
      )}
      <Route component={PageNotFound} />
    </Switch>
  );
};

export default memo(Routes);
