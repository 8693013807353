import {
  Button,
  Card,
  Svg1Icon,
  Svg2Icon,
  Svg3Icon,
  Svg4Icon,
} from '@popsure/dirty-swan';
import Markdown from 'components/markdown';
import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import { generatePath, useHistory, useParams } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

import shield from './icons/shield.svg';
import styles from './style.module.scss';

export const ClaimAmountOverThreshold = () => {
  const { t } = useSafeTranslation();
  const { policyId } = useParams<{ policyId: string }>();
  const history = useHistory<{ from?: string }>();

  return (
    <div className={`mb80 ${styles.animatedContainer}`}>
      <div className="p-body">
        <div className="w100 ai-center d-flex">
          <NavButton
            title={t(
              'claims.expatLongTerm.claimsAmountOverThreshold.navButton.title',
              'Back'
            )}
          />
        </div>
        <div>
          <div className="my16">
            <img src={shield} alt="" />
            <h1 className="p-h1">
              {t(
                'claims.expatLongTerm.claimsAmountOverThreshold.title',
                'How does it work?'
              )}
            </h1>
            <p className="p-p mt16">
              {t(
                'claims.expatLongTerm.claimsAmountOverThreshold.description',
                'For hospital stays, maternity care, and any claim over €3,500, follow these steps:'
              )}
            </p>
          </div>
          <div>
            <Card
              classNames={{
                wrapper: 'wmx8 mb24',
              }}
              verticalAlignment="top"
              icon={<Svg1Icon size={32} noMargin />}
              title={t(
                'claims.expatLongTerm.claimsAmountOverThreshold.card1.title',
                'Have your details ready'
              )}
              titleVariant="medium"
              description={t(
                'claims.expatLongTerm.claimsAmountOverThreshold.card1.description',
                'You will need your certificate number (01.29.1903735), treatment details, and your treating physician’s information.'
              )}
            />
            <Card
              classNames={{
                wrapper: 'wmx8 mb24',
              }}
              verticalAlignment="top"
              icon={<Svg2Icon size={32} noMargin />}
              title={t(
                'claims.expatLongTerm.claimsAmountOverThreshold.card2.title',
                'Call or email for coverage confirmation'
              )}
              titleVariant="medium"
              description={
                <Markdown paragraphClassName="p-p tc-grey-600">
                  {t(
                    'claims.expatLongTerm.claimsAmountOverThreshold.card2.description',
                    'Contact CEGA at [+44 (0)1243 621130](tel:+4401243621130) or email [april-international@cegagroup.com](mailto:april-international@cegagroup.com) to confirm coverage for your treatment.'
                  )}
                </Markdown>
              }
            />
            <Card
              classNames={{
                wrapper: 'wmx8 mb24',
              }}
              verticalAlignment="top"
              icon={<Svg3Icon size={32} noMargin />}
              title={t(
                'claims.expatLongTerm.claimsAmountOverThreshold.card3.title',
                'Wait for the confirmation'
              )}
              titleVariant="medium"
              description={t(
                'claims.expatLongTerm.claimsAmountOverThreshold.card3.description',
                'CEGA will review your claim and let you know what’s covered.'
              )}
            />
            <Card
              classNames={{
                wrapper: 'wmx8 mb24',
              }}
              verticalAlignment="top"
              icon={<Svg4Icon size={32} noMargin />}
              title={t(
                'claims.expatLongTerm.claimsAmountOverThreshold.card4.title',
                'Get treatment'
              )}
              titleVariant="medium"
              description={t(
                'claims.expatLongTerm.claimsAmountOverThreshold.card4.description',
                "Follow CEGA's advice for treatment. Your hospital or physician will handle billing directly with CEGA."
              )}
            />
          </div>
        </div>
        <Button
          className="wmx5 wmn3 mt24 mr16 mb8"
          onClick={() =>
            history.push(
              generatePath(routes.me.policies.detail.path, { policyId })
            )
          }
        >
          {t(
            'claims.expatLongTerm.claimsAmountOverThreshold.button.text',
            'Back to policy'
          )}
        </Button>
      </div>
    </div>
  );
};
